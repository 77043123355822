/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Typography } from "@app/components/typography";
import { useWindowDimensions } from "@app/statics/dimensionContext";
import { styles } from "./styles";
import { Modal } from "antd";
import HubspotForm from "react-hubspot-form";
import { LoadingOutlined } from "@ant-design/icons";

export const MusicServices = () => {
  //CONTEXT
  const { isMobile, WindowDimensionViewWidth } = useWindowDimensions();

  //STATE
  const [showContactForm, setShowContactForm] = React.useState<boolean>(false);

  //DATA
  const data = [
    <p>
      Script and budget analysis, including intellectual property consultancy
      and/or advisory services related to intellectual property rights and
      licensing for music.
    </p>,
    <p>
      Create and maintain budget and music license cost reports for all licensed
      songs and/or custom Work-For-Hire musical pieces, including consulting in
      the field of intellectual property licensing and the field of music
      copyrights.
    </p>,
    <p>
      On-Camera music production analysis and music composition services,
      including advising and/or helping to create prerecords for on-camera music
      performances.
    </p>,
    <p>On-set Production supervision for on-camera music performances.</p>,
    <p>
      Procure and advise on necessary music teams for Production and
      Post-Production needs including music composition services in
      collaboration with artists to help create original songs and themes.
    </p>,
    <p>
      Creative song selection and/or guidance for curated music choices and
      potential soundtrack release.
    </p>,
    <p>
      Educational services, such as providing and participating in or conducting
      as an educational speaker in the fields of music copyright, intellectual
      property consultancy, music composition services, music business
      development, licensing of intellectual property rights and creative song
      selection processes through various forums including industry panels,
      conferences, workshops, seminars, and interviews.
    </p>,
    <p>
      Provide online non-downloadable articles and videos in the field of Music
      Supervision and services such as music prerecords, original music
      composition, intellectual property and music copyright, music
      clearance/licensing and creative music consultancies and collaborations.
    </p>,
  ];

  //RENDER
  return (
    <>
      <section css={styles.wrapper(WindowDimensionViewWidth(85))}>
        <div css={styles.companyName}>
          AGAVE, Music & Media<sup>TM</sup>
        </div>
        <section css={styles.services}>
          <Typography type="h2" bold>
            <>
              The dynamic field of Music Supervision includes the following
              services:
            </>
          </Typography>
          <Typography type="h3">
            <>
              {data.map((paragraph, index) => (
                <React.Fragment key={index}>{paragraph}</React.Fragment>
              ))}
            </>
          </Typography>
          <div>
            <Typography type="h3" bold>
              <>
                Please click
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowContactForm(true);
                  }}
                >
                  {" "}
                  here{" "}
                </a>
                to contact us and to inquire about working together on your next
                project!
              </>
            </Typography>
          </div>
        </section>
      </section>
      <Modal
        open={showContactForm}
        width={isMobile ? "100vw" : WindowDimensionViewWidth(85)}
        closable
        onCancel={() => setShowContactForm(false)}
        footer={null}
        destroyOnClose
      >
        <div css={styles.form}>
          <HubspotForm
            portalId="43876447"
            formId="ee3f27f3-e35e-45a5-aacc-b16f050a6a61"
            loading={
              <div>
                <LoadingOutlined />
              </div>
            }
            css={{ width: "100%" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default MusicServices;
