import React from "react";
import { styles } from "./styles";
import HubspotForm from "react-hubspot-form";
import { Typography } from "@app/components/typography";
import { LoadingOutlined } from "@ant-design/icons";
import { useWindowDimensions } from "@app/statics/dimensionContext";
import Email from "@app/components/email";

export const Contact = () => {
  //CONTEXT
  const { isMobile, WindowDimensionViewWidth } = useWindowDimensions();

  //RENDER
  return (
    <>
      <section css={styles.wrapper(WindowDimensionViewWidth(85))}>
        <section css={styles.mobile320}>
          <Typography type="h1">
            <>Representation</>
          </Typography>
          <Typography type="h2">
            <>
              For Music Supervisor inquiries regarding Janet Lopez please
              contact:
            </>
          </Typography>
          <a
            href="https://evolutionmusicpartners.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Typography type="h3" bold>
              <>Evolution Music Partners</>
            </Typography>
          </a>
          <Typography>
            <>
              <span className="hide-320">
                Contact:&nbsp;&nbsp;{isMobile && <br />}
              </span>
              Christine Russell
              <span className="hide-320"> (</span>
              <br className="show-320" />
              <Email emailAddress="christine@empagency.com" />
              <span className="hide-320">)</span>
              {isMobile ? (
                <>
                  <br />
                  <br className="show-320" />
                </>
              ) : (
                ", "
              )}
              Seth Kaplan
              <span className="hide-320"> (</span>
              <br className="show-320" />
              <Email emailAddress="seth@empagency.com" />
              <span className="hide-320">)</span>
              {isMobile ? (
                <>
                  <br />
                  <br className="show-320" />
                </>
              ) : (
                <>&nbsp;&nbsp;|&nbsp;&nbsp;</>
              )}
              +1-323.790.0525
            </>
          </Typography>
        </section>
        <section>
          <Typography type="h2" center>
            <>For all other inquiries please contact via the form below:</>
          </Typography>
          <Typography center>
            <>Please note, we do not accept unsolicited music submissions.</>
          </Typography>

          <div css={styles.form}>
            <HubspotForm
              portalId="43876447"
              formId="ee3f27f3-e35e-45a5-aacc-b16f050a6a61"
              loading={
                <div>
                  <LoadingOutlined />
                </div>
              }
              css={{ width: "100%" }}
            />
          </div>
        </section>
      </section>
    </>
  );
};

export default Contact;
