import React, { useEffect } from "react";
import { styles } from "./styles";
import { Button, Space } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Typography } from "../typography";
import { Credit } from "@app/statics/contentContext/contentTypes";
import { MobileView } from "react-device-detect";
import { useWindowDimensions } from "@app/statics/dimensionContext";

interface ICreditDetailProps {
  credit?: Credit;
  absolute?: boolean;
  minHeight?: number;
  onClose: () => void;
  standalone?: boolean;
}

export const CreditDetail = (props: ICreditDetailProps) => {
  //CONTEXT
  const { isMobile } = useWindowDimensions();

  //REF
  const youTubeIFrame = React.useRef<HTMLIFrameElement>(null);

  //CREDITS LINK
  const CreditLink = (creditLink: string, type: "imdb" | "tunefind") => {
    let title = "";
    switch (type) {
      case "imdb":
        title = "IMDB";
        break;
      case "tunefind":
        title = "Tunefind";
        break;
    }
    return (
      <div>
        <Button type="primary" color="black" href={creditLink} target="_blank">
          <Space>
            <span>{title}</span>
            <LinkOutlined />
          </Space>
        </Button>
      </div>
    );
  };

  //CHECK IFRAME
  useEffect(() => {
    if (youTubeIFrame.current) {
      youTubeIFrame.current.style.maxHeight = `${
        youTubeIFrame.current.clientWidth / 1.777777777777778
      }px`;
      youTubeIFrame.current.style.minHeight = `${
        youTubeIFrame.current.clientWidth / 1.777777777777778
      }px`;
    }
  }, [youTubeIFrame]);

  //RENDER
  return (
    <div
      css={styles.infoDialog(
        props.absolute ?? false,
        isMobile,
        props.credit?.spotifyId === undefined ? undefined : props.minHeight,
        props.standalone
      )}
    >
      <div>
        <Typography type="h2">
          <>
            {props.credit?.title} ({props.credit?.year}
            {props.credit?.endYear && ` - ${props.credit?.endYear}`})
          </>
        </Typography>
        <div className="__sub">
          <Typography type="h4">
            <>{props.credit?.subtitle}</>
          </Typography>
          <Space>
            {props.credit?.imdbLink &&
              CreditLink(props.credit?.imdbLink, "imdb")}
            {props.credit?.tunefindLink &&
              CreditLink(props.credit?.tunefindLink, "tunefind")}
          </Space>
        </div>

        {props.credit?.youtubeId && (
          <iframe
            ref={youTubeIFrame}
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${props.credit?.youtubeId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            css={{
              borderRadius: "10px",
            }}
          />
        )}
        {props.credit?.spotifyId && props.credit?.spotifyIdCategory && (
          <iframe
            title="spotify"
            src={`https://open.spotify.com/embed/${props.credit?.spotifyIdCategory.toLowerCase()}/${
              props.credit?.spotifyId
            }?utm_source=generator&theme=0`}
            width="100%"
            height="100%"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            css={styles.spotifyIframe(
              props.credit?.spotifyIdCategory,
              props.credit?.youtubeId !== undefined
            )}
          />
        )}
        {props.credit?.soundCloudId && (
          <iframe
            title="soundcloud"
            width="100%"
            height="400px"
            allow="autoplay"
            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${props.credit.soundCloudId}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
          ></iframe>
        )}
        <MobileView css={styles.mobileCloseButton}>
          <Button
            type="primary"
            onClick={() => props.onClose && props.onClose()}
          >
            Close
          </Button>
        </MobileView>
      </div>
    </div>
  );
};

export default CreditDetail;
